import React from "react";
import About from "../../components/about/About";
import Footer from "../../components/FooterNew/Footer";
import Gallery from "../../components/gallery/Gallery";
import GoogleMap from "../../components/googleMap/map";
import HeaderTest from "../../components/headerTest/HeaderTest";
import SocialMediaLinks from "../../components/SocialMediaLinks/SocialMediaLinks";
import Amenities from "../../components/listOfAmenities/listOfAmenities";

import "./home.css";
import HeaderNew from "../../components/headerNew/HeaderNew";

const Home = () => {
  const images = [];

  for (let i = 1; i <= 24; i++) {
    const filePath = `https://firebasestorage.googleapis.com/v0/b/forestaliving-c7feb.appspot.com/o/${i}.jpg?alt=media&token=17625754-1326-46be-84c2-808c5da97c40`;
    images.push(filePath);
  }
  return (
    <React.Fragment>
      {/* <Navbar logoPath={require("../../static/logoImg/forestaLogo.png")} /> */}
      <HeaderTest></HeaderTest>
      <HeaderNew></HeaderNew>
      <SocialMediaLinks></SocialMediaLinks>
      <About></About>
      <Gallery
        images={images}
        title={"Gallery"}
        description={"Gallery from Instagram"}
      ></Gallery>
      <Amenities></Amenities>
      <GoogleMap></GoogleMap>
      <Footer></Footer>
    </React.Fragment>
  );
};

export default Home;
