import "./headerNew.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

const HeaderNew = () => {
  window.addEventListener("scroll", () => {
    const translate = document.querySelectorAll(".translate");
    const big_title = document.querySelector(".big-title");
    const header = document.querySelector("header");
    // const shadow = document.getElementById("about");
    // const content = document.getElementById("about");
    // const section = document.getElementById("about");
    // const image_container = document.querySelector(".imgContainer");
    // const opacity = document.querySelectorAll(".opacity");
    // const border = document.querySelector(".border");

    let header_height = header.offsetHeight;
    // let section_height = section.offsetHeight;
    let scroll = window.pageYOffset;
    // let sectionY = section.getBoundingClientRect();

    translate.forEach((element) => {
      let speed = element.dataset.speed;
      element.style.transform = `translateY(${scroll * speed}px)`;
    });

    // opacity.forEach(element => {
    //     element.style.opacity = scroll / (sectionY.top + section_height);
    // })

    big_title.style.opacity = -scroll / (header_height / 2) + 1;
    // shadow.style.height = `${scroll * 0.5 + 300}px`;

    // content.style.transform = `translateY(${scroll / (section_height + sectionY.top) * 50 - 50}px)`;
    // image_container.style.transform = `translateY(${scroll / (section_height + sectionY.top) * -50 + 50}px)`;

    // border.style.width = `${scroll / (sectionY.top + section_height) * 30}%`;
  });

  return (
    <>
      <div className="headerNew">
        <h1 className="big-title translate" data-speed="0.1">
          Discover Foresta.
        </h1>

        <img
          src={require("../../static/homePageImgMin/person-min.png")}
          className="person translate"
          data-speed="-0.2"
          alt=""
        />
        <img
          src={require("../../static/homePageImgMin/mountain1-min.png")}
          className="mountain1 translate"
          data-speed="0.15"
          alt=""
        />
        <img
          src={require("../../static/homePageImgMin/mountain2-min.png")}
          className="mountain2 translate"
          data-speed="0.4"
          alt=""
        />
        <img
          src={require("../../static/homePageImgMin/mountain3-min.png")}
          className="mountain3 translate"
          data-speed="0.4"
          alt=""
        />
        <img
          src={require("../../static/homePageImgMin/sky-min.png")}
          className="sky translate"
          data-speed="0.5"
          alt=""
        />
      </div>
    </>
  );
};

export default HeaderNew;
