import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import "./SocialMediaLinks.css";

const SocialMediaLinks = () => {
  const handleButtonClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="social-media-links">
      <button
        className="social-media-link instagram"
        onClick={() => handleButtonClick("https://www.instagram.com/foresta_living_/")}
      >
        <FontAwesomeIcon icon={faInstagram} />
      </button>
      <button
        className="social-media-link facebook"
        onClick={() => handleButtonClick("https://www.linkedin.com/company/foresta-living/?originalSubdomain=in")}
      >
        <FontAwesomeIcon icon={faFacebook} />
      </button>
      <button
        className="social-media-link whatsapp"
        onClick={() => handleButtonClick("https://www.whatsapp.com")}
      >
        <FontAwesomeIcon icon={faWhatsapp} />
      </button>
    </div>
  );
};

export default SocialMediaLinks;
