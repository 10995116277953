import { CloseRounded, MenuRounded } from "@mui/icons-material";
import React, {useState, useEffect} from "react";
import "./headerTest.css"; // Import your CSS file for styling

const HeaderTest = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [headerColor, setHeaderColor] = useState('rgba(0,0,0,0.1)');
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const checkWindowWidth = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", checkWindowWidth);
    checkWindowWidth();

    return () => {
      window.removeEventListener("resize", checkWindowWidth);
    };
  }, []);


  useEffect(() => {
    const handleScroll = () => {
      const aboutSection = document.getElementById('about');
      const gallerySection = document.getElementById('gallery');
      const amenitiesSection = document.getElementById('amenities');
      if (aboutSection && gallerySection && amenitiesSection) {
        const aboutSectionTop = aboutSection.offsetTop;
        const gallerySectionTop = gallerySection.offsetTop;
        const amenitiesSectionTop = amenitiesSection.offsetTop;
        const currentScrollY = window.scrollY;

        if(currentScrollY >= aboutSectionTop && currentScrollY < gallerySectionTop) {
          setHeaderColor('rgba(0,0,0,0.5)'); 
        } 
        else if(currentScrollY >= gallerySectionTop && currentScrollY < amenitiesSectionTop) {
          setHeaderColor('rgba(0,0,0,0.6)'); 
        }
        else if (currentScrollY >= amenitiesSectionTop) {
          setHeaderColor('rgba(0,0,0,0.7)'); 
        } else {
          setHeaderColor('rgba(0,0,0,0.1)');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

    return (
        <header className="header" style={{backgroundColor: headerColor}}>
          <div>
            <img className="logoIcon" src={require("../../static/logoImg/forestaLogoName.png")} alt="Logo" />
          </div>
          <nav className={isMenuOpen ? 'nav-menu active ' : 'nav-menu'} style={isMobileView ? { backgroundColor: headerColor}: {}}>
            <ul>
              <li><a className='headerOptions'  href="#about">About</a></li>
              <li><a className='headerOptions'  href="#gallery">Gallery</a></li>
              <li><a className='headerOptions'  href="#amenities">Amenities</a></li>
              <li><a className='headerOptions'  href="#location">How To Reach</a></li>
              { !isMenuOpen && <li><button className="book-now-button">Book Now</button></li>}
            </ul>
          </nav>
          <div className="menu-toggle">
             <div>
              <button className="book-now-button test">Book Now</button>
             </div>
             <div className="menuToggleIcon" onClick={toggleMenu}> 
                {isMenuOpen ?  <CloseRounded></CloseRounded> : <MenuRounded></MenuRounded>}
              </div>
          </div>
        </header>

    );
}

export default HeaderTest;