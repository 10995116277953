import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUtensils,
  // faHotel,
  // faSwimmingPool,
  faWifi,
  faHiking,
  faTree,
  // faSpa,
  faMountain,
  // faGamepad,
  faParking,
} from "@fortawesome/free-solid-svg-icons";
import "./listOfAmenities.css";

const amenitiesData = [
  {
    name: "Breakfast",
    icon: faUtensils,
    description: "Start your day with a delicious breakfast.",
  },
  // {
  //   name: "In-room Dining",
  //   icon: faHotel,
  //   description: "Enjoy in-room dining services at your convenience.",
  // },
  // {
  //   name: "Swimming Pool",
  //   icon: faSwimmingPool,
  //   description: "Take a dip in our scenic swimming pool.",
  // },
  {
    name: "Rustic",
    icon: faTree,
    description: "Embrace the untamed beauty of Himachal in our farm stay.",
  },
  {
    name: "Wi-Fi",
    icon: faWifi,
    description: "Stay connected with complimentary Wi-Fi.",
  },
  {
    name: "Hiking Trails",
    icon: faHiking,
    description: "Explore nearby hiking trails for adventure.",
  },
  // {
  //   name: "Spa",
  //   icon: faSpa,
  //   description: "Relax and rejuvenate with our spa services.",
  // },
  {
    name: "Scenic Views",
    icon: faMountain,
    description: "Enjoy breathtaking mountain views.",
  },
  // {
  //   name: "Game Room",
  //   icon: faGamepad,
  //   description: "Have fun in our game room.",
  // },
  {
    name: "Parking",
    icon: faParking,
    description: "Convenient on-site parking available.",
  },
];

const Amenity = ({ amenity }) => (
  <div className="amenity">
    <FontAwesomeIcon icon={amenity.icon} className="amenity-icon" />
    <h3 className="amenity-name">{amenity.name}</h3>
    <p className="amenity-description">{amenity.description}</p>
  </div>
);

const Amenities = () => (
  <div id="amenities" className="amenities-content">
    {" "}
    {/* Updated div container */}
    <div className="amenities-title">
      <div className="amenities-title-box">
        <h2>Amenities</h2>
      </div>
      <div className="amenities-description-box">
        <p className="amenities-description">Our Services</p>
      </div>
    </div>
    <div className="amenities-container">
      {amenitiesData.map((amenity, index) => (
        <Amenity key={index} amenity={amenity} />
      ))}
    </div>
  </div>
);

export default Amenities;
