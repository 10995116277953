import "./map.css";
import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import LocationCity from "@mui/icons-material/LocationCity";
import PhoneAndroid from "@mui/icons-material/PhoneAndroid";
import Email from "@mui/icons-material/Email";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Map, NearMe } from "@mui/icons-material";

const GoogleMap = () => {
  const Desktop = useMediaQuery("(min-width:1225px)");
  // const Ipad = useMediaQuery('(min-width:1025px)');
  // const Mobile = useMediaQuery('(min-width:320px)');
  const handleExploreNearMe = () => {
    window.open("https://maps.app.goo.gl/pWzNW5WhJ9KS55Q48", "_blank");
  };

  return (
    <div id="location" className="map-container">
      <div className="map-content">
        <div className="map-image">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1104.0100767239114!2d77.31945905010699!3d32.010922487749205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1700851992136!5m2!1sen!2sin"
            width={Desktop ? "650" : "100%"}
            height="450"
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="map-text">
          <h2 className="map-heading">How To Reach</h2>
          <h3 className="map-tagline">
            A Memorable Stay Awaits You in the Lap of Himalayas.
          </h3>

          <p className="map-details">
            Foresta is situated in Kasol, Kullu district about 30 km from
            Bhuntar, about 40 km from Kullu and 75 km from Manali. Foresta has
            good road connectivity and links major cities. Regular buses and
            taxis ply from both Delhi and Chandigarh.
          </p>
          <div className="map-button">
            <button onClick={handleExploreNearMe}>
              <div className="map-button-container">
                <div style={{ marginRight: "0.5rem" }}>View On Maps</div>
                <div>
                  <Map sx={{ fontSize: 30 }}></Map>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleMap;
