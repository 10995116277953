import React, { useState } from "react";
import "./about.css";

const About = () => {
  const [showAboutContent, setShowAboutContent] = useState(false);

  const toggleAboutContent = () => {
    setShowAboutContent(!showAboutContent);
  };

  return (
    <div id="about" className="about-container">
      <h2 className="about-heading">About Foresta</h2>
      <h3 className="about-tagline">
        Welcome to Foresta Living - Your Gateway to Himalayan Tranquility
      </h3>
      {/* Fixed About Content Div */}
      <div className="about-content">
        <div className="about-text">
          <h3 className="about-tagline">Escape. Discover. Unwind.</h3>
          <p className="about-details">
            Experience the perfect blend of relaxation and adventure at Foresta
            Living.
            <br />
            <br />
            Unwind in our cozy private rooms or mingle with fellow travelers in
            our shared dorms and vibrant common spaces.
            <br />
            <br />
            Indulge your taste buds at our in-house cafe, where we serve a
            fusion of continental and local Himachali dishes.
          </p>
        </div>
        <div className="about-image">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/forestaliving-c7feb.appspot.com/o/25.jpg?alt=media&token=17625754-1326-46be-84c2-808c5da97c40"
            alt="About Us"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
      </div>

      {/* Conditionally Rendered About Content Div */}
      {showAboutContent && (
        <div className="about-content">
          <div className="about-image">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/forestaliving-c7feb.appspot.com/o/26.jpg?alt=media&token=17625754-1326-46be-84c2-808c5da97c40"
              alt="About Us"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </div>
          <div className="about-text">
            <h3 className="about-tagline">
              Find Serenity in the Himalayan Paradise.
            </h3>
            <p className="about-details">
              Immerse yourself in the wonders of the Parvati Valley, exploring
              its mesmerizing landscapes and embarking on thrilling treks.
              <br />
              <br />
              Foresta Living serves as an ideal base for adventurers who seek a
              serene yet vibrant getaway.
            </p>
          </div>
        </div>
      )}
      {showAboutContent && (
        <div className="about-content">
          <div className="about-text">
            <h3 className="about-tagline">
              Embrace Adventure in the Heart of Nature.
            </h3>
            <p className="about-details">
              At Foresta Living, we believe in creating an environment that
              celebrates the spirit of youth, adventure, and self-discovery.
              <br />
              <br />
              Join us on this incredible journey and unlock the true essence of
              relaxation, exploration, and connection. Welcome to your home away
              from home at Foresta Living!
            </p>
          </div>
          <div className="about-image">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/forestaliving-c7feb.appspot.com/o/29.jpg?alt=media&token=17625754-1326-46be-84c2-808c5da97c40"
              alt="About Us"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </div>
        </div>
      )}

      {/* Explore More Button that toggles */}
      <div className="explore-button">
        <button onClick={toggleAboutContent}>
          {showAboutContent ? "Minimize" : "Explore More"}
        </button>
      </div>
    </div>
  );
};

export default About;
