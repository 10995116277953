import React from "react";
import About from "../../components/about/About";
import Footer from "../../components/FooterNew/Footer";
import Gallery from "../../components/gallery/Gallery";
import GoogleMap from "../../components/googleMap/map";
import HeaderTest from "../../components/headerTest/HeaderTest";
import SocialMediaLinks from "../../components/SocialMediaLinks/SocialMediaLinks";
import Amenities from "../../components/listOfAmenities/listOfAmenities";

import "./test.css";
import HeaderNew from "../../components/headerNew/HeaderNew";

const Test = () => {
  const images = [
    "https://images.unsplash.com/photo-1581791534721-e599df4417f7?auto=format&fit=crop&q=80&w=1631&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1644008114609-2c8a09b26841?auto=format&fit=crop&q=80&w=1450&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1581791534721-e599df4417f7?auto=format&fit=crop&q=80&w=1631&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1663776376156-2baabcb56486?auto=format&fit=crop&q=80&w=1587&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1581808215902-84b04823297a?auto=format&fit=crop&q=80&w=1548&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1580465874672-a34623c30beb?auto=format&fit=crop&q=80&w=1470&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://curlytales.com/wp-content/uploads/2022/03/hemp.jpg",
    "https://i.redd.it/hwvtewhfp3va1.jpg",
    "https://ik.imagekit.io/shortpedia/Voices/wp-content/uploads/2023/06/The-Terrace-Araiya-Palampur@tripadvisor.jpg",
    "https://static.toiimg.com/photo/msid-89349861,width-96,height-65.cms",
    "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/26/c8/19/3c/the-hosteller-kasol.jpg?w=1200&h=-1&s=1",
    "https://q-xx.bstatic.com/xdata/images/hotel/840x460/420935580.jpg?k=30b59d02b99ac07298a88069c4061a93471d40ea420b535ace83e14337c7ace4&o=",
  ];

  return (
    <React.Fragment>
      {/* <Navbar logoPath={require("../../static/logoImg/forestaLogo.png")} /> */}
      <HeaderTest></HeaderTest>
      <HeaderNew></HeaderNew>
      <SocialMediaLinks></SocialMediaLinks>
      <About></About>
      <Gallery
        images={images}
        title={"Gallery"}
        description={"Gallery from Instagram"}
      ></Gallery>
      <Amenities></Amenities>
      <GoogleMap></GoogleMap>
      <Footer></Footer>
    </React.Fragment>
  );
};

export default Test;
