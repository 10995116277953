import React, { useState } from "react";
import "./gallery.css";

const Gallery = ({ title, images, description }) => {
  const [selectedImg, setSelectedImg] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImg(image);
  };

  const handleOverlayClick = () => {
    setSelectedImg(null);
  };

  return (
    <div id="gallery" className="gallery">
      <div className="gallery-title">
        <div className="gallery-title-box">
          <h2>{title}</h2>
        </div>
        <div className="gallery-description-box">
          <p className="gallery-description">{description}</p>
        </div>
      </div>
      <div className="image-grid">
        {images.map((image, index) => (
          <div
            key={index}
            className="image-item"
            onClick={() => handleImageClick(image)}
          >
            <img src={image} alt={`gallery-img-${index}`} />
          </div>
        ))}
      </div>
      {selectedImg && (
        <div className="overlay" onClick={handleOverlayClick}>
          <img src={selectedImg} alt="selected" />
        </div>
      )}
    </div>
  );
};

export default Gallery;
